<template>
  <div class="flex justify-center flex-col mt-16 mb-12">
    <div class="text-size-20 text-center">
      {{ content.chooseColor }}
    </div>
    <div class="mt-12 flex justify-center items-center flex-wrap">
      <ColorRadioButton
        v-for="item in colors"
        :key="item.id"
        :title="item.title"
        :value="item.id"
        :price="item.label"
        :color="item.color"
        :selectedItem="color"
        @setColor="setColor"
      />
    </div>
    <p class="error text-center mt-8 h-8">
      {{ error }}
    </p>
  </div>
</template>

<script>
import ColorRadioButton from "/src/components/ui/Buttons/ColorRadioButton.vue";

import { format } from "/src/assets/numberFormat";

export default {
  components: {
    ColorRadioButton,
  },
  inject: ["content"],
  props: ["color", "error"],
  emits: ["setColor"],
  computed: {
    colors() {
      let colors = this.$store.getters["materials/colors"];
      return colors.filter(color => color.refill != null);
    },
  },
  methods: {
    setColor(value) {
      this.$emit("setColor", value);
    },
    format(price) {
      return format(price);
    },
  },
};
</script>
