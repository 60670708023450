<template>
  <div class="flex justify-center flex-col mt-16 mb-12">
    <div class="text-size-20 text-center">
      {{ content.chooseScent }}
    </div>
    <div class="mt-12 flex justify-center items-center flex-wrap">
      <ScentRadio
        v-for="item in scents"
        :key="item.id"
        :title="item.title"
        :value="item.id"
        :selectedItem="scent"
        @setScent="setScent"
        :img="item.image"
      />
    </div>
    <p class="error text-center mt-8 h-8">
      {{ error }}
    </p>
  </div>
</template>

<script>
import ScentRadio from "/src/components/ui/Buttons/ScentRadio.vue";
export default {
  components: {
    ScentRadio,
  },
  inject: ["content"],
  props: ["scent", "error"],
  emits: ["setScent"],
  computed: {
    scents() {
      return this.$store.getters["materials/scents"];
    },
  },
  methods: {
    setScent(value) {
      this.$emit("setScent", value);
    },
  },
};
</script>