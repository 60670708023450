<template>
  <div class="flex justify-center flex-col mt-16 mb-12">
    <div class="text-size-20 text-center">
      {{ content.chooseWickType }}
    </div>
    <div class="mt-12 flex justify-center items-center flex-wrap">
      <WickRadio
        v-for="item in wicks"
        :key="item.id"
        :title="item.title"
        :value="item.id"
        :selectedItem="wick"
        @setWick="setWick"
        :img="item.image"
        :price="item.price"
      />
    </div>
    <p class="error text-center mt-8 h-8">
      {{ error }}
    </p>
  </div>
</template>

<script>
import WickRadio from "/src/components/ui/Buttons/WickRadio.vue";
export default {
  components: {
    WickRadio,
  },
  props: ["wick", "error"],
  emits: ["setWick"],
  inject: ["content"],
  computed: {
    wicks() {
      return this.$store.getters["materials/wicks"];
    },
  },
  methods: {
    setWick(value) {
      this.$emit("setWick", value);
    },
  },
};
</script>