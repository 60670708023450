<template>
  <div class="order-summery-card">
    <div>
      <div class="text-center lg:mt-0 mt-12 text-bluePrimary pt-6 lora-bold">
        {{ content.yourOrderSummery }}
      </div>
      <hr style="color: #37328b00" class="my-4" />
      <div class="flex justify-between px-4 xl:px-6">
        <div class="col-span-2 lora-bold">
          {{ content.itemSpecifications }}
        </div>
        <div class="col-span-1 lora-bold text-align-end">
          {{ content.cost }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-4">
        <div class="col-span-2 me-4 text-start">
          {{ content.container }}: {{ containerText }}
        </div>
        <div class="col-span-1 whitespace-nowrap text-align-end">
          {{ containerPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-4">
        <div class="col-span-2 me-4 text-start">
          {{ content.scent }}: {{ scentText }}
        </div>
        <div
          class="col-span-1 whitespace-nowrap text-align-end"
          v-if="scentPrice"
        >
          {{ scentPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-4">
        <div class="col-span-2 me-4 text-start">
          {{ content.wick }}: {{ wickText }}
        </div>
        <div
          class="col-span-1 whitespace-nowrap text-align-end"
          v-if="wickPrice"
        >
          {{ wickPrice }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-6 mt-4">
        <div class="col-span-2 me-4 text-start">
          {{ content.color }}: {{ colorText }}
        </div>
        <div
          class="col-span-1 whitespace-nowrap text-align-end"
          v-if="colorPrice"
        >
          {{ colorPrice }} {{ content.iqd }}
        </div>
      </div>

      <hr style="color: #37328b22" class="my-4 mx-4" />

      <div class="flex justify-between px-4 xl:px-6 mt-4 text-size-16">
        <div></div>
        <div class="whitespace-nowrap">{{ content.totalPerItem }}:</div>
        <div class="text-align-end whitespace-nowrap">
          {{ totalPerItem }} {{ content.iqd }}
        </div>
      </div>
      <div
        class="flex justify-between flex-wrap px-4 xl:px-6 mt-4 text-size-16"
      >
        <div></div>
        <div class="whitespace-nowrap">{{ content.quantity }}:</div>
        <div class="text-align-end whitespace-nowrap">x {{ quantity }}</div>
      </div>
      <div
        v-if="delivery && city"
        class="flex justify-between flex-wrap px-4 xl:px-6 mt-4 text-size-16"
      >
        <div></div>
        <div class="whitespace-nowrap">{{ content.deliveryCost }}:</div>
        <div class="text-align-end whitespace-nowrap">{{ format(city.price) }} {{ content.iqd }}</div>
      </div>
      <div class="flex justify-between flex-wrap px-4 xl:px-6 text-size-16">
        <div></div>
        <div class="col-span-4 whitespace-nowrap">
          <hr style="color: #37328b22" class="my-4 mx-4" />
        </div>
      </div>
      <div
        class="
          flex
          justify-between
          flex-wrap
          px-4
          gap-5
          xl:px-6
          mt-4
          text-size-16
          pb-6
          flex-col-reverse
          items-end
        "
      >
        <div class="flex justify-between">
          <!--       <button
            class="
              header-btn
              text-bluePrimary
              hover:text-white hover:bg-bluePrimary
              lora-bold
              whitespace-nowrap
              bg-blueSecondary
              duration-300
            "
          >
            {{ content.printEstimate }}
          </button> -->
        </div>
        <div class="w-40 flex items-center justify-between">
          <div class="col-span-1 flex items-center whitespace-nowrap">
            {{ content.total }}:
          </div>
          <div
            class="
              col-span-2
              flex
              items-center
              justify-end
              text-align-end
              whitespace-nowrap
            "
          >
            {{ totalPrice }} {{ content.iqd }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "../../assets/numberFormat";

export default {
  inject: ["content"],
  props: [
    "containerPrice",
    "containerText",
    "scentText",
    "scentPrice",
    "wickPrice",
    "wickText",
    "colorPrice",
    "colorText",
    "totalPerItem",
    "quantity",
    "totalPrice",
    "city",
    "delivery"
  ],
  methods: {
    format(input){
      return format(input)
    }
  }
};
</script>