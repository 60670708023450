<template>
  <div
    class="
      flex
      w-max-content
      my-2
      me-6
      border-with-radius
      pe-16
      ps-4
      py-2
      cursor-pointer
    "
    :class="{ 'selected-container': selectedItem == value }"
    @click="$emit('updateValue', value)"
  >
    <div
      class="radio-gift-fake-check me-4"
      :class="{ 'selected-radio': selectedItem == value }"
    >
      <i class="fas fa-check"></i>
    </div>
    <div class="text-start">
      <div>{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["updateValue"],
  props: ["text", "value", "selectedItem"],
};
</script>