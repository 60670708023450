<template>
  <div class="flex justify-center flex-col mt-16 mb-12">
    <div class="text-size-20 text-center">
      {{ content.chooseContainerRefill }}
    </div>
    <div class="mt-6 flex justify-center items-center flex-wrap" v-if="containers">
      <SimpleRadioButton
        v-for="(container, index) in containers"
        :key="index"
        :text="container.title"
        :value="container.id"
        :selectedItem="request.container"
        @updateValue="setContainer"
      />
      <!-- <SimpleRadioButton
        :text="content.lalaContainers"
        :value="true"
        :selectedItem="request.container"
        @updateValue="setContainer"
      />
      <SimpleRadioButton
        :text="content.nonLalaContainers"
        :value="false"
        :selectedItem="request.container"
        @updateValue="setContainer"
      /> -->
    </div>
    <p class="error text-center my-4">
      {{ errors?.container }}
    </p>
    <div class="flex justify-center items-center flex-wrap" v-if="sizes">
      <ContainerRadioButton
        v-for="size in sizes"
        :key="size.id"
        :title="size.title"
        :value="size.id"
        :selectedItem="request.size"
        @setContainer="setSize"
      />
    </div>
    <p class="error text-center mt-8 h-8">
      {{ errors?.size }}
    </p>
  </div>
</template>

<script>
import SimpleRadioButton from "/src/components/ui/Buttons/SimpleRadioButton.vue";
import ContainerRadioButton from "/src/components/ui/Buttons/ContainerRadioButton.vue";

export default {
  components: {
    SimpleRadioButton,
    ContainerRadioButton,
  },
  inject: ["content"],
  props: ["request", "errors"],
  emits: ["setContainer", "setSize"],
  computed: {
    sizes() {
      return this.$store.getters["materials/sizes"];
    },
    containers(){
      return this.$store.getters["materials/refillContainers"];
    }
  },
  methods: {
    setContainer(value) {
      this.$emit("setContainer", value);
    },
    setSize(value) {
      this.$emit("setSize", value);
    },
  },
};
</script>